export const storePersist = {
  set: (key, state) => {
    window.localStorage.setItem(key, state);
  },
  get: (key) => {
    const result = window.localStorage.getItem(key);
    return result;
  },
  remove: (key) => {
    window.localStorage.removeItem(key);
  },
  getAll: () => {
    return window.localStorage;
  },
  clear: () => {
    window.localStorage.clear();
  },
};

export default storePersist;
