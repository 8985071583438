import { API_BASE_URL } from '@/config/serverApiConfig';

import axios from 'axios';
import errorHandler from '@/request/errorHandler';
import successHandler from '@/request/successHandler';

export const login = async ({ loginData }) => {
  const url = 'https://tradex.kredallino.com/login?code=production';
  console.log('loginData:', loginData);

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const body = new URLSearchParams({
    'grant_type': 'password',
    'username': loginData.email,
    'password': loginData.password,
    'scope': 'indicator_client',
    'client_id': 'string',
    'client_secret': 'string',
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // If access token is present in the response, store it in localStorage
    if (data?.result?.accessToken) {
      localStorage.setItem('access_token', JSON.stringify(data.result.accessToken));
    }

    // Handle success and failure as needed
    successHandler(
      { data, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true,
      }
    );

    return data;
  } catch (error) {
    console.error('Login failed:', error);
    return errorHandler(error);
  }
};


export const logout = async () => {
  axios.defaults.withCredentials = true;
  try {
    window.localStorage.clear();
    await axios.post(API_BASE_URL + `logout?timestamp=${new Date().getTime()}`);
  } catch (error) {
    return errorHandler(error);
  }
};
