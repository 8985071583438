import * as actionTypes from './types';
import * as authService from '@/auth';

import history from '@/utils/history';

export const login =
  ({ loginData }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.LOADING_REQUEST,
        payload: { loading: true },
      });
      const data = await authService.login({ loginData });
      console.log('data:', data);

      if (data.access_token) {
        window.localStorage.setItem('isLoggedIn', true);
        window.localStorage.setItem('auth', data.access_token);
        dispatch({
          type: actionTypes.LOGIN_SUCCESS,
          payload: data.access_token,
        });
        history.push('/');
      } else {
        dispatch({
          type: actionTypes.FAILED_REQUEST,
          payload: data,
        });
      }
    };

export const logout = () => async (dispatch) => {
  authService.logout();
  dispatch({
    type: actionTypes.LOGOUT_SUCCESS,
  });
  history.push('/login');
};
