console.log("process.env.NODE_ENV", process.env.NODE_ENV)
// export const API_BASE_URL =  "http://localhost:8888/api/"
export const API_BASE_URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_DEV_REMOTE === 'remote'
    ? 'https://learning-power.kredallino.tech/api/'
    : 'https://learning-power.kredallino.tech/api/';
console.log("API BASE URL", API_BASE_URL)

export const ACCESS_TOKEN_NAME = 'x-auth-token';


async function login(username, password) {
    console.log("login with username", username, password);
    const url = 'https://tradex.kredallino.com/login?code=production';
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    const body = new URLSearchParams({
        'grant_type': 'password',
        'username': username,
        'password': password,
        'scope': 'indicator_client',
        'client_id': 'string',
        'client_secret': 'string'
    });

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: body
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);
        return data;
    } catch (error) {
        console.log('Error In Tradex Login Detail Here ->', error);
    }
}