

export const routesConfig = [


  {
    path: '/',
    component: 'Form',
  },

];
